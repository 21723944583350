import React, { useEffect, useState } from "react";
import queryStringSerialize from "@/utils/queryStringSerialize";
import { Fab, IconButton, TablePagination } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import authHeaders from "@/lib/authHeaders";
import { useSnackbar } from "notistack";
import NcrForm from "./form/NcrForm";
import { serialize } from "object-to-formdata";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import useTable from "@/components/datatable/useTable";
import getNCRTableColumns from "@/pages/hseq/ncr/getNCRTableColumns";
import { useSearchParamsState } from "@/hooks/useSearchParamsState";

const NewNcrFab = ({ onClick, formOnly }) => {
  if (formOnly) {
    return (
      <IconButton onClick={onClick} size="large">
        <AddCircleIcon />
      </IconButton>
    );
  } else
    return (
      <Fab
        onClick={onClick}
        color="secondary"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 999,
        }}
      >
        <AddIcon />
      </Fab>
    );
};

const url = `${import.meta.env.VITE_VTS_API}/v1/ncr`;

const filtersDefaults = {
  owner: { type: "string", default: "" },
  production_number: { type: "string", default: "" },
  job_number: { type: "string", default: "" },
  purchase_number: { type: "string", default: "" },
  ncr_type: { type: "string", default: "" },
  level: { type: "string", default: "" },
};

const Ncr = ({ formOnly, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
  const {
    owner,
    production_number,
    job_number,
    purchase_number,
    ncr_type,
    level,
  } = filterParams;
  const { state, handlers, setters } = useTable({
    columns: getNCRTableColumns(),
    options: {
      multiSort: false,
      mode: "client",
      debug: false,
    },
  });
  const {
    columns,
    filters,
    getPaginatedRows,
    loading,
    page,
    rows,
    rowsPerPage,
  } = state;
  const {
    handlePageChange,
    handleChangeRowsPerPage,
    handleRemoveFilter,
    handleSearch,
    handleToggleSort,
  } = handlers;
  const { setFilters, setLoading, setRows, setTotalRows } = setters;

  const errorHandler = (error) => {
    try {
      error.json();
      return error.then((body) => {
        enqueueSnackbar(`${body.status}: ${body.message}`, {
          variant: "error",
        });
        errorHandlerEnd();
      });
    } catch (e) {
      enqueueSnackbar("Failed to load data", {
        variant: "error",
      });
      errorHandlerEnd();
    }
  };

  const errorHandlerEnd = () => {
    if (!formOnly) {
      const id = setInterval(() => {
        navigate("/ncr");
        clearInterval(id);
      }, 1500);
    }
  };

  const getFilterFromSearchParams = () => {
    let filter = {};
    for (let property in filterParams) {
      if (filterParams[property].length > 0) {
        filter[property] = {
          value: filterParams[property],
          text: filterParams[property],
        };
      }
    }
    return filter;
  };

  const handleFilterApply = (filter) => {
    setFilters(filter);
    updateSearchParams(filter);
  };

  const updateSearchParams = (filter) => {
    const params = getSearchParamsFromFilter(filter);
    setFilterParams(params);
  };

  const getSearchParamsFromFilter = (filter) => {
    let params = {};
    for (let property in filter) {
      params[property] = filter[property].value;
    }
    return params;
  };

  const handleFilterRemove = (key) => {
    handleRemoveFilter(key);

    setFilterParams({ [key]: "" });
  };

  const handleReloadTable = () => {
    fetchData();
  };

  const handleOpenForm = () => {
    if (!formOnly) {
      navigate("/ncr/new");
    }
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setFormData({});
    setShowForm(false);

    if (searchParams.has("new")) {
      searchParams.delete("new");
      setSearchParams(searchParams);
    }

    if (!formOnly) {
      navigate(-1);
    }
  };

  const handleCreate = (formData) => {
    return fetch(url, {
      method: "POST",
      headers: authHeaders({ defaultContentType: true }),
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          typeof onSubmit === "function" && onSubmit();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleUpdate = (formData, id) => {
    return fetch(`${url}/${id}`, {
      method: "PUT",
      headers: authHeaders({ defaultContentType: true }),
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleSubmit = (form) => {
    const formData = serialize(form);

    if (form.id) {
      handleUpdate(formData, form.id);
    } else {
      handleCreate(formData);
    }

    handleCloseForm();
  };

  const handleDelete = (id) => {
    fetch(`${url}/${id}`, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch(errorHandler);
  };

  const handleRowClick = (rowData) => {
    const { id } = rowData;
    if (id) {
      navigate(`/ncr/${id}`);
    }
  };

  const handleEdit = (rowData, _e) => {
    const { id } = rowData;

    setShowForm(true);

    if (id === "new") {
      return;
    }

    setFormLoading(true);

    fetch(`${url}/${id}`, {
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setFormData(data);
        setFormLoading(false);
      })
      .catch((error) => {
        handleCloseForm();
        errorHandler(error);
        setFormLoading(false);
      });
  };

  async function fetchData(params) {
    setLoading(true);
    return await fetch(`${url}?${queryStringSerialize(params)}`, {
      headers: authHeaders(),
    })
      .then((r) => r.json())
      .then((r) => {
        const rows = r.rows.map((row) => {
          return {
            id: row.id,
            created_at: row.created_at,
            ncr_reference: row.ncr_reference,
            title: row.title,
            ncr_type: row.ncr_type,
            level: row.level,
            owner: row.owner,
            production_number: row.one_d.company.production_number,
            job_number: row.one_d.company.job_number,
            purchase_number: row.one_d.company.purchase_number,
          };
        });
        setRows(rows);
        setTotalRows(r.total);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
        return error;
      });
  }
  useEffect(() => {
    if (id) {
      handleEdit({ id });
    } else {
      setShowForm(false);
    }
  }, [id]);

  useEffect(() => {
    const newNCR = searchParams.get("new");
    if (newNCR === "true") setShowForm(true);
  }, [searchParams]);

  if (formOnly) {
    return (
      <>
        <NewNcrFab formOnly={formOnly} onClick={handleOpenForm} />
        <NcrForm
          open={showForm}
          onClose={handleCloseForm}
          data={formData}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          loading={formLoading}
        />
      </>
    );
  }

  useEffect(() => {
    const filter = getFilterFromSearchParams();
    setFilters(filter);
  }, [owner, production_number, job_number, purchase_number, ncr_type, level]);

  useEffect(() => {
    fetchData();

    const filter = getFilterFromSearchParams();
    handleFilterApply(filter);
  }, []);

  return (
    <>
      <NewNcrFab formOnly={formOnly} onClick={handleOpenForm} />
      <FullpageTableScrollContainer>
        <MaterialToolbar
          filters={filters}
          loading={loading}
          columns={columns}
          onSearch={handleSearch}
          onRemoveFilter={handleFilterRemove}
        />
        <MaterialDataTable
          TableContainerProps={{ sx: { overflow: "initial" } }} // important to get body scroll working
          onApplyFilter={handleFilterApply}
          onRemoveFilter={handleFilterRemove}
          onRowClick={handleRowClick}
          onToggleSort={(_e, column) => handleToggleSort(column.key)}
          enableFiltering
          {...state}
          rows={getPaginatedRows()}
        />
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20, 50, { value: -1, label: "All" }]}
          rowsPerPage={rowsPerPage}
          count={rows.length}
          page={page}
          onPageChange={(e, newIndex) => handlePageChange(newIndex)}
          onRowsPerPageChange={(e) => {
            handleChangeRowsPerPage(parseInt(e.target.value));
          }}
          disabled={rows.length <= 0}
          sx={{ m: 1 }}
        />
      </FullpageTableScrollContainer>
      <NcrForm
        open={showForm}
        onClose={handleCloseForm}
        data={formData}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        loading={formLoading}
      />
    </>
  );
};

export default Ncr;

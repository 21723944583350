import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ListIcon from "@mui/icons-material/List";
import TimelineIcon from "@mui/icons-material/Timeline";
import CachedIcon from "@mui/icons-material/Cached";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ViewListIcon from "@mui/icons-material/ViewList";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import StorageIcon from "@mui/icons-material/Storage";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";

import {
  ConfigureReportsIcon,
  EmployeesPerDayIcon,
  NcrIcon,
  ProgressPerResourceIcon,
  QueueListIcon,
  WeldDefectsIcon,
} from "./icons";
import BackButton from "@/pages/purchase/create-receival-orders/BackButton";
import filterRoutes from "./filterRoutes";

//documents
import Mrb from "@/pages/documents/Mrb";
import TFMCImports from "@/pages/documents/tfmc-import/TFMCImport";
import ViewDocument from "@/pages/documents/view-document/ViewDocument";

//purchase
import ItemsToBuy from "@/pages/purchase/items-to-buy/ItemsToBuy";
import ItemsToReceive from "@/pages/purchase/items-to-receive/ItemsToReceive";
import ProductionTimeline from "@/pages/production/timeline/ProductionTimeline";
import WorkCenterLoad from "@/pages/production/work-center-load/WorkCenterLoad";
import CreateReceivalOrders from "@/pages/purchase/create-receival-orders/CreateReceivalOrders";
import WarehouseReceival from "@/pages/purchase/warehouse-receival/WarehouseReceival";

//reports
import WeldMap from "@/pages/reports/weld-maps/WeldMap";
import WeldMaps from "@/pages/reports/weld-maps/WeldMaps";
import WeldLog from "@/pages/reports/weld-log/WeldLog";
import WeldDefects from "@/pages/reports/weld-defects/WeldDefects";

// items
import ReceiptControl from "@/pages/items/receipt-control/ReceiptControl";
import PrintableReceiptLabel from "@/pages/purchase/create-receival-orders/PrintableReceiptLabel";

//config
import EmployeesPerDay from "@/pages/config/EmployeesPerDay";

//project
import OnTimeDelivery from "@/pages/project/on-time-delivery/OnTimeDelivery";
import Comments from "@/pages/project/comments/Comments";

//Components
import ProjectComponents from "@/pages/project/components/ProjectComponents";

//hseq
import Ncr from "@/pages/hseq/ncr/Ncr";
import AddNcrFromIframe from "@/pages/hseq/ncr/AddNcrFromIframe";

//production
import ProgressPerResource from "@/pages/production/progress-per-resource/ProgressPerResource";
import QueueList from "@/pages/production/queue-list/QueueList";
import WorkCenters from "@/pages/production/queue-list/work-centers/WorkCenters";
import DeliveryPlan from "@/pages/project/delivery-plans/DeliveryPlan";
import HomeButtons from "@/pages/home/HomeButtons";
import Home from "@/pages/home/Home";
import Planning from "@/pages/project/planning/Planning";

export type Roles = "admin" | "ot_admin" | "ot_user" | "client" | "guest" | "";

export type RoutePage = {
  title: string;
  subtitle?: string;
  icon?: React.ComponentType<any>;
  path: string | null;
  component?: React.ComponentType<any> | string;
  fullPage?: boolean;
  hidden?: boolean;
  buttons?: React.ComponentType<any>;
  permittedUserRole?: Roles[];
  onClick?: () => void;
};

export type RouteConfig = {
  category: string;
  pages: RoutePage[];
};

const IFRAME_ROOT_URL = import.meta.env.VITE_VTS_ENDPOINT;

//category and their pages
const routeConfig = (railsUserId: string): RouteConfig[] => [
  {
    category: "Dashboards",
    pages: [
      {
        title: "Home",
        path: "/home",
        icon: HomeOutlinedIcon,
        buttons: HomeButtons,
        component: Home,
      },
    ],
  },
  {
    category: "HSEQ",
    pages: [
      {
        title: "NCR",
        icon: NcrIcon,
        path: "/ncr",
        component: Ncr,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
        hidden: true,
      },
      {
        title: "NCR Edit",
        icon: NcrIcon,
        path: "/ncr/:id",
        component: Ncr,
        hidden: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Overview",
        icon: EqualizerIcon,
        path: "/ncr/overview",
        component: IFRAME_ROOT_URL + "/hseq/ncr_overview",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Details",
        icon: ChromeReaderModeOutlinedIcon,
        path: "/ncr/details",
        component: IFRAME_ROOT_URL + "/hseq/ncr_details",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Age Destribution",
        icon: PieChartOutlinedIcon,
        path: "/ncr/age-distrbution",
        component: IFRAME_ROOT_URL + "/hseq/ncr_age_distribution",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Categories",
        icon: CategoryOutlinedIcon,
        path: "/ncr-categories",
        component: IFRAME_ROOT_URL + "/hseq/ncr_categories",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Progress",
        icon: ShowChartIcon,
        path: "/ncr/progress",
        component: IFRAME_ROOT_URL + "/hseq/ncr_progress",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR List",
        icon: ListIcon,
        path: "/ncr/list",
        component: IFRAME_ROOT_URL + "/hseq/ncr_list",
        buttons: AddNcrFromIframe,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "NCR Root Cause",
        icon: TimelineIcon,
        path: "/ncr/root-cause",
        component: IFRAME_ROOT_URL + "/hseq/ncr_root_cause",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Print NCR",
        icon: PrintOutlinedIcon,
        path: "/ncr/print-ncr",
        component: IFRAME_ROOT_URL + "/hseq/print_ncr",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Production",
    pages: [
      {
        title: "Queue List",
        subtitle: "Work Centers",
        icon: QueueListIcon,
        path: "/production/queue-list/work-centers",
        component: WorkCenters,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Queue List",
        icon: TimelineIcon,
        path: "/production/queue-list",
        component: QueueList,
        fullPage: true,
        hidden: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Production Queue List",
        icon: QueueOutlinedIcon,
        path: "/production/work-centers",
        component: IFRAME_ROOT_URL + "/production/work_centers",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "In Progress",
        icon: CachedIcon,
        path: "/production/in-progress",
        component: IFRAME_ROOT_URL + "/production/in_progress",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Load and Capacity",
        icon: FitnessCenterIcon,
        path: "/production/load-and-capacity",
        component: IFRAME_ROOT_URL + "/production/load_and_capacity",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Work Center Load",
        icon: WorkOutlineOutlinedIcon,
        path: "/production/work-center-load",
        component: WorkCenterLoad,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Production Order",
        icon: ShoppingBasketOutlinedIcon,
        path: "/production/production-order",
        component: IFRAME_ROOT_URL + "/production/orders",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Operations",
        icon: BuildOutlinedIcon,
        path: "/production/operations",
        component: IFRAME_ROOT_URL + "/production/operations",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Progress per Resource",
        icon: ProgressPerResourceIcon,
        path: "/production/progress-per-resource",
        component: ProgressPerResource,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },
      {
        title: "Progress Per Resource",
        subtitle: "Old version",
        icon: FindReplaceIcon,
        path: "/production/progress-per-resource/old",
        component: IFRAME_ROOT_URL + "/production/progress_per_resource",
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },

      {
        title: "Supplier View",
        icon: ViewListIcon,
        path: "/production/supplier-view",
        component: IFRAME_ROOT_URL + "/production/supplier_view",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Trace List",
        icon: ListIcon,
        path: "/production/trace-list",
        component: IFRAME_ROOT_URL + "/production/trace_list",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Production Timeline",
        icon: TimelineIcon,
        path: "/production/timeline",
        component: ProductionTimeline,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Project",
    pages: [
      {
        title: "Deliveries",
        icon: LocalShippingOutlinedIcon,
        path: "/project/deliveries",
        component: IFRAME_ROOT_URL + "/project/deliveries",
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },
      {
        title: "Deliveries Timeline",
        icon: LocalShippingOutlinedIcon,
        path: "/project/delivery-plans",
        component: DeliveryPlan,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Hours Planned",
        icon: ScheduleIcon,
        path: "/project/hours-planned",
        component: IFRAME_ROOT_URL + "/project/hours_planned",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Old Comments",
        path: "/project/old-comments",
        icon: CommentOutlinedIcon,
        component: IFRAME_ROOT_URL + "/project/comments",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Comments",
        icon: CommentOutlinedIcon,
        path: "/project/comments",
        component: IFRAME_ROOT_URL + "/comments",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Comments",
        subtitle: "Beta",
        icon: CommentOutlinedIcon,
        path: "/project/comments-beta",
        component: Comments,
        fullPage: true,
        hidden: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Add Comment",
        icon: CommentOutlinedIcon,
        path: "/project/comments/new",
        hidden: true,
        component: IFRAME_ROOT_URL + "/comments/new",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Tasks",
        icon: AssignmentOutlinedIcon,
        path: "/project/tasks",
        component: IFRAME_ROOT_URL + "/project/tasks",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Components",
        icon: ViewModuleOutlinedIcon,
        path: "/project/components",
        component: ProjectComponents,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Customer View",
        icon: SupervisedUserCircleOutlinedIcon,
        path: "/project/customer-view",
        component: IFRAME_ROOT_URL + "/project/customer_view",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Finance",
        icon: AttachMoneyIcon,
        path: "/project/finance",
        component: IFRAME_ROOT_URL + "/project/finance",
        permittedUserRole: ["admin", "ot_admin"],
      },
      {
        title: "On Time Delivery",
        icon: HourglassEmptyIcon,
        path: "/project/on-time-delivery",
        component: OnTimeDelivery,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Components Timeline",
        icon: TimelineIcon,
        path: "/project/components-timeline",
        component: IFRAME_ROOT_URL + "/project/components/index_old",
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },
      {
        title: "Planning",
        icon: ScheduleIcon,
        path: "/project/planning",
        component: Planning,
        permittedUserRole: ["admin", "ot_admin"],
      },
    ],
  },
  {
    category: "Items",
    pages: [
      {
        title: "Inventory",
        icon: StorageIcon,
        path: "/items/inventory",
        component: IFRAME_ROOT_URL + "/item/inventory",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Customer Inventory",
        icon: StoreOutlinedIcon,
        path: "/items/customer-inventory",
        component: IFRAME_ROOT_URL + "/item/inventory/customer",
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },
      {
        title: "BOM",
        icon: ReceiptOutlinedIcon,
        path: "/items/bom",
        component: IFRAME_ROOT_URL + "/item/bom",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Programs and Tools",
        icon: BuildOutlinedIcon,
        path: "/items/tools",
        component: IFRAME_ROOT_URL + "/item/programs_and_tools",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Lessons Learned",
        icon: MenuBookIcon,
        path: "/items/lessons-learned",
        component: IFRAME_ROOT_URL + "/item/lessons_learned",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Routing",
        icon: FiberManualRecordOutlinedIcon,
        path: "/items/routing",
        component: IFRAME_ROOT_URL + "/item/routing",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Receipt Control",
        icon: ListAltIcon,
        path: "/items/receipt-control",
        component: ReceiptControl,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Documents",
    pages: [
      {
        title: "Add Document",
        icon: NoteAddOutlinedIcon,
        path: "/documents/documents-new",
        component: IFRAME_ROOT_URL + "/documents/documents/new",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Documents",
        icon: DescriptionOutlinedIcon,
        path: "/documents/documents",
        component: IFRAME_ROOT_URL + "/documents/documents",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "SMDR",
        icon: DescriptionOutlinedIcon,
        path: "/documents/smdr",
        component: IFRAME_ROOT_URL + "/documents/smdr",
        permittedUserRole: ["admin", "ot_admin", "ot_user", "client"],
      },
      {
        title: "MRB",
        icon: DescriptionOutlinedIcon,
        path: "/documents/mrb",
        component: Mrb,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        hidden: true,
        title: "Add MRB",
        path: "/documents/mrbs/new",
        component: IFRAME_ROOT_URL + "/documents/mrbs/new",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "ECN",
        icon: DescriptionOutlinedIcon,
        path: "/documents/ecn",
        component: IFRAME_ROOT_URL + "/documents/ecn",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "ITP",
        icon: DescriptionOutlinedIcon,
        path: "/documents/itp",
        component: IFRAME_ROOT_URL + "/documents/itp",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Check Active",
        icon: DescriptionOutlinedIcon,
        path: "/documents/check-active",
        component: IFRAME_ROOT_URL + "/documents/actions/check_active",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "TFMC Import",
        icon: UploadFileIcon,
        path: "/documents/tfmc-import",
        component: TFMCImports,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "View Document",
        path: "/documents/view-document/:documentId",
        component: ViewDocument,
        fullPage: true,
        hidden: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Reports",
    pages: [
      {
        title: "Weld log",
        icon: DescriptionOutlinedIcon,
        path: "/reports/weld-log/:id",
        component: WeldLog,
        hidden: true,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Reports",
        icon: DescriptionOutlinedIcon,
        path: "/reports/reports",
        component: IFRAME_ROOT_URL + "/reports/reports",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Old QC Reports",
        icon: DescriptionOutlinedIcon,
        path: "/reports/qc-report",
        component: IFRAME_ROOT_URL + "/qc_reports",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Weld Maps",
        icon: DescriptionOutlinedIcon,
        path: "/reports/weld-maps",
        component: WeldMaps,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Weld Defects",
        icon: WeldDefectsIcon,
        path: "/reports/weld-defects",
        component: WeldDefects,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Show Weld map",
        path: "/reports/weld-map/:id",
        component: WeldMap,
        hidden: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Create Weld Map",
        icon: DescriptionOutlinedIcon,
        path: "/reports/weld-maps/new",
        component: IFRAME_ROOT_URL + "/weld_maps/new",
        hidden: true,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Purchase",
    pages: [
      {
        title: "Purchase Orders",
        icon: ShoppingBasketOutlinedIcon,
        path: "/purchase/orders",
        component: IFRAME_ROOT_URL + "/purchase/orders",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Receipts",
        icon: ReceiptOutlinedIcon,
        path: "/purchase/receipts",
        component: IFRAME_ROOT_URL + "/purchase/receipts",
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Items to buy",
        icon: ShoppingCartOutlinedIcon,
        path: "/purchase/items-to-buy",
        component: ItemsToBuy,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Items to receive",
        icon: AddShoppingCartIcon,
        path: "/purchase/items-to-receive",
        component: ItemsToReceive,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Warehouse receival",
        icon: WarehouseOutlinedIcon,
        path: "/purchase/warehouse-receival",
        component: WarehouseReceival,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Create receival orders",
        icon: PostAddOutlinedIcon,
        path: "/purchase/create-receival-orders",
        component: CreateReceivalOrders,
        fullPage: true,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
    ],
  },
  {
    category: "Config",
    pages: [
      {
        title: "Users",
        icon: GroupOutlinedIcon,
        path: "/users",
        component: IFRAME_ROOT_URL + "/users",
        permittedUserRole: ["admin", "ot_admin"],
      },
      {
        title: "Employees Per Day",
        icon: EmployeesPerDayIcon,
        path: "/config/employees-per-day",
        component: EmployeesPerDay,
        permittedUserRole: ["admin", "ot_admin", "ot_user"],
      },
      {
        title: "Configure Reports",
        icon: ConfigureReportsIcon,
        path: "/config/reports",
        component: IFRAME_ROOT_URL + "/reports/configurations",
        permittedUserRole: ["admin", "ot_admin"],
      },
    ],
  },
  {
    category: "User",
    pages: [
      {
        title: "Profile",
        icon: AccountBoxOutlinedIcon,
        path: `/profile/${railsUserId}`,
        component: `${IFRAME_ROOT_URL}/users/${railsUserId}`,
      },
    ],
  },
  {
    category: "Support",
    pages: [
      {
        title: "Eprovider",
        subtitle: "Email support",
        path: null,
        icon: EmailOutlinedIcon,
        onClick: () => window.open("mailto:support@eprovider.no"),
      },
      {
        hidden: true,
        title: "Print label",
        buttons: BackButton,
        path: "/purchase/create-receival-orders/print-label",
        component: PrintableReceiptLabel,
      },
    ],
  },
];

const routes = () => {
  const { railsUserId, railsUserRole } = JSON.parse(
    localStorage?.getItem("msToken") ?? "",
  ) as { railsUserId: string; railsUserRole: Roles };

  //Flattens the routeConfig and filters out the routes that the user does not have access to
  return filterRoutes(routeConfig(railsUserId), railsUserRole);
};

export default routes;
